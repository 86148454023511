<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="add-entity">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">Ajouter une entité</div>
      </q-card-section>

      <q-card-section>
        <div class="form-container">
          <q-form @submit="onSubmit" class="q-gutter-md">
            <AutocompleteEntity
              label="Nom de l'entité"
              @setEditLabelTitle="setEditLabelTitle"
            />

            <div class="radio-container">
              <div class="label">
                Type d'entité
              </div>
              <div class="radio-wrapper">
                <q-radio v-model="type" val="COMPANY" label="Personne morale" />

                <q-radio v-model="type" val="PERSON" label="Personne physique" />
              </div>
            </div>

            <div class="bottom-buttons">
              <q-btn label="Annuler" class="btn-cancel" @click="closeModal" />

              <q-btn label="Sauvegarder" class="btn-save" type="submit">
                <q-icon class="save-icon" />
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AutocompleteEntity from '@/components/project/AutocompleteEntity'

export default {
  name: 'AddEntityModal',
  components: { AutocompleteEntity },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      type: 'COMPANY'
    }
  },
  computed: {
    ...mapGetters('projects', ['getProject', 'getErrorSendingEntity'])
  },
  methods: {
    ...mapActions('projects', ['addNewEntity', 'fetchEntitiesProject']),
    closeModal() {
      this.$emit('closeModal')
    },
    async onSubmit() {
      const data = {
        entityName: this.name.trim(),
        entityAlternativeNames: [`${this.name.trim()} 2`], //hardcode
        entityType: this.type,
        forceCreate: false,
        projectId: this.getProject.projectId
      }

      await this.addNewEntity(data)

      // if error show error
      if (this.getErrorSendingEntity.length) return

      const { projectId } = this.$route.params
      this.fetchEntitiesProject({ projectId })

      this.name = ''
      this.type = 'COMPANY'

      this.$emit('closeModal')
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    checkName(e) {
      if (!e.data?.trim().length && !this.name.trim().length) this.name = ''
    },
    setEditLabelTitle(title) {
      this.name = title.trim()
    }
  },
  watch: {
    getErrorSendingEntity: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
